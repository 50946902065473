import { useState } from "react"

import { Icon } from "@/assets"
import ProfileModal from "./profileModal"

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import { logout } from "@/redux/slices/auth"
import { useAppDispatch } from "@/redux/hooks"
import { useGetUserQuery, userApi } from "@/api/resources"
import Avatar from "./Avatar"
import { useNavigate } from "react-router-dom"
import { toast } from "sonner"

export default function ProfileSettings() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { data: user } = useGetUserQuery()
  const [openProfileSettings, setOpenProfileSettings] = useState<boolean>(false)

  const [isDropdownOpen, setDropdownOpen] = useState(false)

  const doLogout = () => {
    dispatch(logout())
    dispatch(userApi.util.resetApiState())

    toast.success("Logged out successfully")
    navigate("/login")
  }

  return (
    <>
      <ProfileModal
        isOpen={openProfileSettings}
        setOpen={setOpenProfileSettings}
      />
      <div className="relative">
        <DropdownMenu open={isDropdownOpen} onOpenChange={setDropdownOpen}>
          <DropdownMenuTrigger asChild>
            <button className="w-10 h-10 rounded-50 border border-blue-200 flex justify-center items-center">
              <Avatar size={32} name={user?.first_name} photo={user?.photo} />
            </button>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="w-40 py-2" align="end">
            <DropdownMenuItem
              className="py-3"
              onClick={() => setOpenProfileSettings(true)}
            >
              <Icon name="IProfile" className="mr-2" /> Profile Settings
            </DropdownMenuItem>
            <DropdownMenuItem className="py-3" onClick={doLogout}>
              <Icon name="Logout" className="mr-2" /> Log Out
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </>
  )
}
