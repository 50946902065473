import { useParams } from "react-router-dom"

import { Project } from "@/components"
import { useGetProjectsQuery } from "@/api/resources"
import { useMemo } from "react"
// import { ProjectProvider } from "@/context/projectContext"
import ProjectContext, {
  ProvisionProjectProvider,
} from "@/components/Project/ProjectContext"

export default function page() {
  const { id } = useParams()
  const { data: projects, isLoading } = useGetProjectsQuery()

  const project = useMemo(() => {
    if (projects) {
      return projects.find((x) => x.project_id == id)
    }
    return undefined
  }, [projects, id])

  // TODO: say that project doesn't exist, or show an error toast if isError
  if (!project && !isLoading) {
    return <div>project not found</div>
  }

  return project ? (
    <ProvisionProjectProvider>
      <ProjectContext.Provider value={project}>
        <Project project={project} />
      </ProjectContext.Provider>
    </ProvisionProjectProvider>
  ) : (
    <div>loading ...</div>
  )
}
