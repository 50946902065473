import StatusForm from "./statusForm"

const sortOptions = [
  "More to less completed",
  "Less to more completed",
  "Recent First",
  "Oldest First",
  "A-Z",
  "Z-A",
]

interface SortProps {
  handleCheckboxChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  selectedStatuses: string[]
}

export default function Sort({
  handleCheckboxChange,
  selectedStatuses,
}: SortProps) {
  return (
    <div
      className=" absolute right-0 top-6 border border-border-gray items-start z-10 text-15 leading-6 bg-blue-100 rounded-10 p-4 pb-2 flex flex-col gap-2.5"
      style={{ color: "#5E8AB6" }}
    >
      {sortOptions.map((option, _) => (
        <button key={_}>{option}</button>
      ))}

      <div className="flex flex-col gap-2 items-start">
        <p className="font-500 text-black">Status:</p>
        <StatusForm
          selectedStatuses={selectedStatuses}
          handleCheckboxChange={handleCheckboxChange}
        />
      </div>
    </div>
  )
}
