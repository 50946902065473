import { Icon } from "@/assets"
import { StatusEnum } from "@/types/status"

const statusOptions = [
  StatusEnum.untouchable,
  StatusEnum.inProgress,
  StatusEnum.inReview,
  StatusEnum.done,
]

interface StatusFormProps {
  selectedStatuses: string[]
  handleCheckboxChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export default function StatusForm({
  selectedStatuses,
  handleCheckboxChange,
}: StatusFormProps) {
  return (
    <>
      {statusOptions.map((status, _) => (
        <label key={_} className="flex items-center">
          <div className="relative w-max">
            <input
              type="checkbox"
              value={status}
              checked={selectedStatuses.includes(status)}
              onChange={handleCheckboxChange}
              className="mr-2 opacity-0"
            />
            <div
              className="border-2 flex items-center justify-center rounded-3 absolute top-1 left-0 w-4 h-4"
              style={{
                border: "1px solid #B6CADE",
                background: `${
                  selectedStatuses.includes(status) ? "#B6CADE" : "transparent"
                }`,
              }}
            >
              {selectedStatuses.includes(status) && <Icon name="Tick" />}
            </div>
          </div>
          {status}
        </label>
      ))}
    </>
  )
}
