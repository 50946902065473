import { Link } from "react-router-dom"

import { Logo } from "@/assets"
import Search from "../left/main/searchBar/search"
import Paths from "./paths"
import ProfileSettings from "./profileSettings"

export default function AppHeader() {
  return (
    <div className="py-4 gap-4 flex justify-between items-center wrapper">
      <Link to="/" className="min-w-[78px]">
        <img src={Logo} alt={"logo"} />
      </Link>
      <Paths />
      <Search
        // query= string;
        // handleChange= (e: React.ChangeEvent<HTMLInputElement>) => void;
        // handleKeyPress= (e: React.KeyboardEvent<HTMLInputElement>) => void;
        placeholder="Search project, folder or document"
        classname="py-3 rounded-9 w-full max-w-[479px]"
      />
      <ProfileSettings />
    </div>
  )
}
