import Documents from "@/components/Documents"
import { criteriaData } from "@/data/criteria"
import { Dropdown } from "@/elements"
import {
  useProjectContext,
  useProvisionProjectContext,
} from "@/components/Project/ProjectContext"
import { useGetProjectDocumentsByProvisionQuery } from "@/api/resources"
import SemanticMatchedDocuments from "@/components/Documents/SemanticMatchedDocuments"

export default function SemanticTab() {
  const { provision, setProvision } = useProvisionProjectContext()
  const project = useProjectContext()

  const { data = [], isLoading } = useGetProjectDocumentsByProvisionQuery({
    project_id: project?.project_id || "",
    provision,
  })

  return (
    <div>
      <Dropdown
        value={provision}
        onChange={(val) => setProvision(val)}
        placeholder="Choose a value..."
        options={criteriaData}
        icon="BlueDownarrow"
        resetOnDestroy
        currentBtnClassName="bg-bgSecondary w-full rounded-12 py-[17px] px-4 flex justify-between items-center font-500 text-16 text-gray-500"
        optDivClassName="absolute top-12 left-0 bg-bgSecondary rounded-b-10 flex flex-col p-4 w-full z-10"
        optBtnClassName="block hover:bg-gray-200 hover:rounded-10 py-3 px-4 w-full text-left leading-6 font-400 text-16 text-gray-500"
      />

      <div className="flex flex-col gap-4 pt-10">
        {isLoading ? (
          <div>Loading...</div>
        ) : provision ? (
          <SemanticMatchedDocuments documents={data} provision={provision} />
        ) : (
          <Documents matches={data.length} documents={data} />
        )}
      </div>
    </div>
  )
}
