import { useView } from "@/context/ViewContext"
import ReadOnlyEditor from "./readOnlyEditor"
import ReadWriteEditor from "./readWriteEditor"
import {
  SemanticMatch,
  useDocumentContext,
} from "@/components/left/DocumentContext"
import {
  Highlight,
  PdfHighlighter,
  PdfHighlighterUtils,
  useHighlightContainerContext,
  ViewportHighlight,
  PdfLoader,
  MonitoredHighlightContainer,
  AreaHighlight,
} from "react-pdf-highlighter-extended"
import { API_URL } from "@/config"
import { useAppSelector } from "@/redux/hooks"
import { useEffect, useMemo, useRef, useState } from "react"

const colors = {
  lightGreen: "#86EFAC",
  darkGreen: "#16A34D",
  darkRed: "#FF4D4D",
  lightRed: "#FFB3B3",
  lightGray: "#3C608366",
  darkGray: "#99B5D1",
}

interface MatchHighlight extends Highlight {
  match: SemanticMatch
}

const MatchAreaHighlight = ({
  activeMatch,
  hoveredChunk,
  onMouseEnter,
}: {
  activeMatch: number | null
  hoveredChunk: number | null
  onMouseEnter?: (data: ViewportHighlight<MatchHighlight>) => void
}) => {
  const { highlight, isScrolledTo, highlightBindings } =
    useHighlightContainerContext<MatchHighlight>()

  const isMachineMatched = highlight.match.value === 1
  const isUserValidated = highlight.match.isUserValidated === 1
  const isHovered = hoveredChunk === highlight.match.index
  const isActive = activeMatch === highlight.match.index

  let color: keyof typeof colors | undefined = undefined

  if (isUserValidated) {
    color = isActive ? "darkGreen" : "lightGreen"
  } else {
    if (isMachineMatched) {
      color = isActive ? "darkRed" : "lightRed"
    } else if (isHovered) {
      color = "lightGray"
    } else if (isActive) {
      color = "darkGray"
    }
  }

  return (
    <MonitoredHighlightContainer
      key={highlight.id}
      onMouseEnter={() => {
        onMouseEnter?.(highlight)
      }}
    >
      <AreaHighlight
        isScrolledTo={isScrolledTo}
        highlight={highlight}
        style={{
          backgroundColor: color ? colors[color] : "transparent",
        }}
      />
    </MonitoredHighlightContainer>
  )
}

const PdfView = () => {
  const {
    documentId,
    semanticSearchData: data,
    activeMatch,
    allMatches,
  } = useDocumentContext()
  const authToken = useAppSelector((state) => state.auth.accessToken)
  const pdfDocument = useMemo(
    () => ({
      url: `${API_URL}/documents/${documentId}?foo`,
      httpHeaders: {
        Authorization: `Bearer ${authToken}`,
        Accept: "application/octet-stream",
      },
    }),
    [documentId, authToken],
  )

  const { splitView } = useView()
  const [highlights, setHighlights] = useState<MatchHighlight[]>([])
  const [hoveredChunk, setHoveredChunk] = useState<number | null>(null)

  const triggeredRef = useRef(false)
  const highlighterUtilsRef = useRef<PdfHighlighterUtils>()

  useEffect(() => {
    if (allMatches) {
      const newHighlights = allMatches.flatMap((match) => {
        if (!data || !data.text_chunks || !data.page_xyxy) return []

        return data.text_chunks[match.index].components.map(
          ({ page, xyxy: [x1, y1, x2, y2] }, idx) => {
            const pageBox = data.page_xyxy![page]
            const pageWidth = pageBox[2] - pageBox[0]
            const pageHeight = pageBox[3] - pageBox[1]

            return {
              id: `${match.index}-${idx}`,
              type: "area",
              match,
              position: {
                rects: [],
                boundingRect: {
                  pageNumber: page + 1,
                  width: pageWidth,
                  height: pageHeight,
                  x1,
                  x2,
                  y1,
                  y2,
                },
              },
            } satisfies MatchHighlight
          },
        )
      })

      setHighlights(newHighlights)

      // Hacky way to scroll to url target match when the page is loaded
      // We only want this to run once, since running this multiple times
      // will cause the page to scroll to the target match multiple times
      // when scrolling through the pdf document, since the content is lazy loaded
      if (activeMatch !== null && location.hash && !triggeredRef.current) {
        const hi = newHighlights.find((x) => x.match.index === activeMatch)

        if (hi && highlighterUtilsRef.current) {
          highlighterUtilsRef.current.scrollToHighlight(hi)
          triggeredRef.current = true
        }
      }
    }
  }, [allMatches, hoveredChunk, activeMatch])

  useEffect(() => {
    if (activeMatch !== null) {
      const hi = highlights.find((x) => x.match.index === activeMatch)

      if (hi) {
        highlighterUtilsRef.current?.scrollToHighlight(hi)
      } else {
        console.warn("Highlight item hasn't been loaded yet!!!")
      }
    }
  }, [activeMatch])

  return (
    <div className={`py-0 mr-0 max-h-screen w-1/2 overflow-y-scroll`}>
      <div
        className={` ${
          splitView ? "p-8" : "px-0 pb-0"
        } bg-bgSecondary rounded-10 flex flex-col`}
      >
        <section className="bg-white">
          <PdfLoader document={pdfDocument}>
            {(pdfDocument) => (
              <PdfHighlighter
                style={{ border: "1px solid red", width: "50%" }}
                pdfDocument={pdfDocument}
                utilsRef={(_pdfHighlighterUtils) => {
                  highlighterUtilsRef.current = _pdfHighlighterUtils
                }}
                highlights={highlights}
              >
                <MatchAreaHighlight
                  activeMatch={activeMatch}
                  hoveredChunk={hoveredChunk}
                  onMouseEnter={(highlight) => {
                    setHoveredChunk(highlight.match.index)
                  }}
                />
              </PdfHighlighter>
            )}
          </PdfLoader>
        </section>
      </div>
    </div>
  )
}

export default function Right({ keywords, setMatches, activeMatch }) {
  const { splitView } = useView()
  const { document, htmlFile } = useDocumentContext()

  return (
    <>
      {document?.file_type === "pdf" && <PdfView />}
      {htmlFile && (
        <ReadOnlyEditor
          keywords={keywords}
          setMatches={setMatches}
          activeMatch={activeMatch}
        />
      )}
      {splitView && <ReadWriteEditor />}
    </>
  )
}
