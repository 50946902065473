import { Icon } from "@/assets"
import DocumentDropdown from "@/components/Documents/document/DocumentDropdown"
import { ProjectStatus } from "@/elements"
import { Link } from "react-router-dom"
import { DocumentSchema } from "@/api/resources"
import clsx from "clsx"
import PulsingIndicator, { PulsingIndicatorProps } from "./PulsingIndicator"

interface DocumentProps {
  document: DocumentSchema
  toggleSelected: (id: string) => void
  selected: string[]
}

export default function Document({
  document,
  toggleSelected,
  selected,
}: DocumentProps) {
  const mentions = document.search_hits?.length ?? 0
  const indexStatus = document.index_status

  return (
    <section
      onClick={() => toggleSelected(document.document_id)}
      className={clsx(
        "overflow-hidden flex flex-col gap-2 justify-between rounded-14 p-6 pr-4 border w-full",
        selected.includes(document.document_id)
          ? "border-blue-200"
          : "border-border-gray",
      )}
    >
      <div className="flex items-center justify-between">
        <div className="flex gap-2 items-center">
          {indexStatus !== "success" ? (
            <PulsingIndicator
              variant={indexStatus === "pending" ? "pending" : "error"}
              tooltips={{
                pending: "Processing your document.",
                error: "Document processing failed",
              }}
            />
          ) : null}

          <p
            className={clsx(
              "rounded-5 px-1.5 text-12 leading-6 text-text-neutral bg-[#F0F4F8]",
              mentions ? "block" : "hidden",
            )}
          >
            {mentions} Mentions
          </p>
        </div>

        {/* TODO: n_mentions is confusing because right now it's n_keywords */}
        <DocumentDropdown document={document} />
      </div>

      <Link
        className="flex flex-col gap-2 items-center flex-1"
        to={`/document/${document.document_id}`}
      >
        <Icon name="Document" />
        <div className="flex text-black flex-col gap-1 text-15 leading-4 break-words w-full flex-1">
          <p className="flex-1 text-center">{document.display_name} </p>
          <p className="text-gray-400 text-center">{document.title ?? "-"}</p>
        </div>

        <p className="text-14 leading-4 text-gray py-2 uppercase flex gap-1 items-center">
          <span className="opacity-40">{document?.file_type ?? "??"}</span>
          {document.language ? (
            <span className="bg-blue-200 py-0.5 px-1 rounded-md text-[12px] text-white uppercase opacity-80">
              {document.language}
            </span>
          ) : null}
        </p>

        <ProjectStatus status={document?.status ?? ""} />
      </Link>
    </section>
  )
}
