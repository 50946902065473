import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog"
import { useDeleteDirectoryMutation } from "@/api/resources"
import { useNavigate } from "react-router-dom"
import { toast } from "sonner"

export function DeleteFolderDialog({ open, onOpenChange, directoryId }) {
  const navigate = useNavigate()
  const [deleteDirectory, { isLoading: isDeleting }] =
    useDeleteDirectoryMutation()

  const doDelete = async () => {
    const toastLoadingId = toast.loading("Deleting folder...")
    const result = await deleteDirectory(directoryId)
    if (result.error) {
      toast.error("Failed to delete folder.", {
        id: toastLoadingId,
      })
    } else {
      toast.success("Folder deleted successfully.", {
        id: toastLoadingId,
      })
      navigate(-1)
    }
  }

  return (
    <AlertDialog open={open} onOpenChange={onOpenChange}>
      {/*<AlertDialogTrigger asChild>*/}
      {/*  <Button variant="outline">Show Dialog</Button>*/}
      {/*</AlertDialogTrigger>*/}
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
          <AlertDialogDescription>
            This action cannot be undone. This will permanently delete the item
            and remove data from the server.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <AlertDialogAction
            variant="destructive"
            onClick={doDelete}
            disabled={isDeleting}
          >
            Delete
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
