"use client"
import { useMemo, useState } from "react"

import Keywords from "@/components/keywords"
import Search from "@/components/left/main/searchBar/search"
import FilesystemView from "../../FilesystemView"
import {
  ResourceSchema,
  useGetProjectQuery,
  useSearchDocumentsQuery,
} from "@/api/resources"

export default function KeywordsTab({ project }) {
  const [query, setQuery] = useState<string>("")
  const [keywords, setKeywords] = useState<string[]>([])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setQuery(e.target.value)

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault()
      console.log("KeywordsTab searching for:", query)

      setKeywords([...keywords, query])
      setQuery("")
    }
  }
  const deleteKeyword = (idx: number) => {
    const existingKeywords = [...keywords]
    existingKeywords.splice(idx, 1)
    setKeywords(existingKeywords)
  }

  // ***
  const isSearchingByKeywords = useMemo(() => {
    return keywords !== undefined && keywords.length > 0
  }, [keywords])

  // TODO: don't we have this already in context?
  const { data: fullData } = useGetProjectQuery(
    { project_id: project?.project_id },
    { skip: isSearchingByKeywords },
  )
  const { data: searchData } = useSearchDocumentsQuery(
    { keywords, project_id: project?.project_id },
    { skip: !isSearchingByKeywords },
  )

  const data: ResourceSchema[] | undefined = isSearchingByKeywords
    ? searchData
    : fullData?.contents
  // ***

  return (
    <div>
      <Keywords
        keywords={keywords}
        parentClass={`flex-wrap pb-4 ${
          keywords.length > 0 ? "block" : "hidden"
        }`}
        childClass={
          "rounded-6 bg-background-darkGrey text-text-darkGrey font-500 text-16 leading-4 flex gap-2 items-center w-fit p-2"
        }
        deleteKeyword={deleteKeyword}
      />

      <Search
        query={query}
        handleChange={handleChange}
        handleKeyPress={handleKeyPress}
        placeholder="Type keywords ⏎, or paste from excel"
        classname="placeholder-gray py-5 rounded-8"
        autoFocus
      />

      {/*TODO*/}
      <FilesystemView isSearchingKeywords={keywords.length > 0} data={data} />
    </div>
  )
}
