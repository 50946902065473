import { useEffect, useRef, useState } from "react"

import { useDocumentContext } from "@/components/left/DocumentContext"
import { cn } from "@/lib/utils"
import { Checkbox } from "@/components/ui/checkbox"
import { Button } from "@/components/ui/button"
import { useUpdateSemanticLabelsMutation } from "@/api/resources"
import { toast } from "sonner"
import { useNavigate } from "react-router-dom"

export default function CriteriaMatch() {
  const navigate = useNavigate()

  const {
    activeMatch,
    setActiveMatch,
    documentId,
    semanticSearchClass,
    allMatches,
  } = useDocumentContext()
  const [updateLabels] = useUpdateSemanticLabelsMutation()
  const [isShowAll, setIsShowAll] = useState(false)
  const targetMatch = useRef<HTMLDivElement>(null)
  const triggeredRef = useRef<boolean>(false)

  const matchedOnly = allMatches.filter(
    (x) => x.value === 1 || x.isUserValidated === 1,
  )
  const matchedOnlyIdx = matchedOnly.map((x) => x.index)
  const baseMatches = isShowAll ? allMatches : matchedOnly

  const [bufferMatches, setBufferMatches] = useState<typeof allMatches>([])

  useEffect(() => {
    // We need to keep track of allMatches changes, so we can't just pass this
    // as a parameter to the useState
    setBufferMatches(allMatches.map((match) => ({ ...match })))

    if (allMatches.length === 0 && semanticSearchClass) {
      // Not sure if we need to keep this; however, for presentation purposes I'll put it up here.
      toast.error(
        `Semantic matches not yet processed for "${semanticSearchClass.replaceAll("_", " ").toWellFormed()}."`,
        {
          description:
            "Please wait for the semantic search to finish processing.",
        },
      )
    }
  }, [allMatches])

  useEffect(() => {
    // We need to keep track of allMatches changes, so we can't just pass this
    // as a parameter to the useState
    setBufferMatches(allMatches.map((match) => ({ ...match })))
  }, [allMatches])

  const filteredBufferMatches = isShowAll
    ? bufferMatches
    : bufferMatches.filter(
        (x) => matchedOnlyIdx.includes(x.index) || x.isUserValidated === 1,
      )

  const noChanges =
    allMatches.length === 0 ||
    filteredBufferMatches.every(
      (match) =>
        match.isUserValidated === allMatches[match.index].isUserValidated,
    )

  useEffect(() => {
    // Workaround to make the selected matched line from the project's
    // semantic tab to be scrolled to when the page loads, also we only want
    // this to trigger once, or else it will be scrolled to whenever we
    // check / uncheck other lines
    if (
      activeMatch !== null &&
      location.hash &&
      targetMatch.current &&
      !triggeredRef.current
    ) {
      targetMatch.current?.scrollIntoView({
        block: "center",
        inline: "center",
      })
      triggeredRef.current = true
    }
  }, [filteredBufferMatches])

  useEffect(() => {
    let urlFragment = `?provision=${semanticSearchClass}`
    if (activeMatch !== null && !isNaN(activeMatch)) {
      urlFragment += `#${activeMatch}`
    }

    navigate(urlFragment, { replace: true })

    return () => {
      // Clear url params when navigating away
      navigate("", { replace: true })
    }
  }, [activeMatch, semanticSearchClass])

  return (
    <div className="pt-6 flex flex-col gap-4">
      <div className="flex justify-between items-center">
        <p className="font-400 text-16 leading-6 text-gray-400">
          <span className="text-black">{baseMatches.length}</span> matches found
        </p>

        <div className="flex gap-2">
          <Button
            variant="outline"
            onClick={() => {
              setIsShowAll(!isShowAll)
              setActiveMatch(null)
            }}
          >
            {isShowAll ? "Show Match Only" : "Show all"}
          </Button>
          <Button
            disabled={noChanges}
            onClick={async () => {
              const toastLoadingid = toast.loading("Updating labels...")

              const result = await updateLabels({
                document_id: documentId,
                target_class: semanticSearchClass ?? "",
                changed_labels: bufferMatches
                  .map((match, i) =>
                    match.isUserValidated !== allMatches[i].isUserValidated
                      ? match.index
                      : null,
                  )
                  .filter((x) => x !== null),
              })

              if (result.error) {
                toast.error("Error updating labels", {
                  description: (result.error as any).data.detail,
                  id: toastLoadingid,
                })
              } else {
                toast.success("Labels were successfully updated.", {
                  id: toastLoadingid,
                })
              }
            }}
          >
            Save
          </Button>
        </div>
      </div>

      {filteredBufferMatches.map((match) => (
        <div
          key={match.index}
          ref={activeMatch === match.index ? targetMatch : null}
          className={cn(
            "flex flex-col gap-4 rounded-9 p-8 font-400 text-16 leading-[20.16px] text-ellipsis whitespace-nowrap overflow-hidden cursor-pointer transition-colors",
            match.isUserValidated
              ? activeMatch === null || activeMatch !== match.index
                ? "bg-green-200/50 text-green-800"
                : "bg-green-300 text-green-800"
              : match.value === 1
                ? activeMatch === null || activeMatch !== match.index
                  ? "bg-red-200/60 text-red-800"
                  : "bg-red-300 text-red-800"
                : activeMatch === null || activeMatch !== match.index
                  ? "bg-gray-200 text-gray-900"
                  : "bg-gray-300 text-gray-900",
          )}
          onClick={() => {
            setActiveMatch(match.index === activeMatch ? null : match.index)
          }}
        >
          <div className="text-inherit flex items-center gap-2">
            <p className="block w-[calc(100%-16px)] overflow-hidden whitespace-nowrap text-ellipsis text-16 leading-4 font-400">
              {match.text}
            </p>

            <Checkbox
              checked={match.isUserValidated === 1}
              className="data-[state='checked']:bg-green-500 data-[state='checked']:text-white bg-white border-gray-400"
              onClick={(e) => {
                e.stopPropagation()
                const newBufferMatches = bufferMatches.slice()
                newBufferMatches[match.index].isUserValidated =
                  newBufferMatches[match.index].isUserValidated === 1 ? 0 : 1
                setBufferMatches(newBufferMatches)
              }}
            />
          </div>
        </div>
      ))}
    </div>
  )
}
