import { z } from "zod"
import { useForm } from "react-hook-form"
import { isValidPhoneNumber } from "react-phone-number-input"
import { zodResolver } from "@hookform/resolvers/zod"
import { toast } from "sonner"

import { Icon } from "@/assets"

import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog"
import { Input, ToggleableInput } from "@/components/ui/input"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs"
import { Button } from "@/components/ui/button"
import { PhoneInput } from "@/components/ui/phone-input"

import {
  useGetUserQuery,
  useUpdateUserMutation,
  useUpdateUserPasswordMutation,
} from "@/api/resources"

import UpdateAvatarModal from "../UpdateAvatarModal"
import Avatar from "../Avatar"

const profileFormSchema = z.object({
  firstName: z.string({ message: "First name is required" }),
  lastName: z.string({ message: "Last name is required" }),
  email: z.string({ message: "Email is required" }).email(),
  phone: z
    .string()
    .nullable()
    .optional()
    .refine(
      (val) => {
        if (!val) return true
        return isValidPhoneNumber(val)
      },
      { message: "Invalid phone number" },
    ),
})

function ChangeProfileData() {
  const { data: userInfo } = useGetUserQuery()
  const [updateUser] = useUpdateUserMutation()

  const form = useForm<z.infer<typeof profileFormSchema>>({
    resolver: zodResolver(profileFormSchema),
    values: {
      firstName: userInfo?.first_name || "",
      lastName: userInfo?.last_name || "",
      email: userInfo?.email || "",
      phone: userInfo?.phone || null,
    },
  })

  async function handleUserUpdate(data: z.infer<typeof profileFormSchema>) {
    // If the data is the same as the user info, don't update
    if (
      data.firstName === userInfo?.first_name &&
      data.lastName === userInfo?.last_name &&
      data.email === userInfo?.email &&
      data.phone === userInfo?.phone
    ) {
      return
    }

    const loadingToastId = toast.loading("Updating your profile...")

    const result = await updateUser({
      first_name: data.firstName,
      last_name: data.lastName,
      phone: data.phone ?? null,
      email: data.email,
    })

    if (result.error) {
      if (
        "data" in result.error &&
        "detail" in (result.error.data as Record<string, unknown>)
      ) {
        const detail = (result.error.data as { detail: string }).detail

        toast.error("Failed to update profile!", {
          description: detail,
          id: loadingToastId,
        })

        if (detail.includes("email")) {
          form.setError("email", { type: "manual", message: detail })
        } else if (detail.includes("phone")) {
          form.setError("phone", { type: "manual", message: detail })
        }
      } else {
        toast.error("Failed to update profile!", {
          id: loadingToastId,
        })
      }
    } else {
      toast.success("Updated profile!", {
        description: "Your profile has been updated successfully.",
        id: loadingToastId,
      })
    }
  }

  return (
    <Form {...form}>
      <form
        className="flex flex-col gap-2"
        onSubmit={form.handleSubmit(handleUserUpdate)}
      >
        <div className="flex gap-4">
          <FormField
            name="firstName"
            control={form.control}
            render={({ field }) => (
              <FormItem className="flex-grow">
                <FormLabel>First name</FormLabel>
                <FormControl>
                  <Input
                    placeholder="First name"
                    type="text"
                    autoComplete="given-name"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            name="lastName"
            control={form.control}
            render={({ field }) => (
              <FormItem className="flex-grow">
                <FormLabel>Last name</FormLabel>
                <FormControl>
                  <Input
                    placeholder="Last name"
                    type="text"
                    autoComplete="family-name"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <FormField
          name="email"
          control={form.control}
          render={({ field }) => (
            <FormItem>
              <FormLabel>E-mail</FormLabel>
              <FormControl>
                <Input
                  placeholder="email"
                  type="email"
                  autoComplete="email"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          name="phone"
          control={form.control}
          render={({ field }) => (
            <FormItem>
              <FormLabel>Phone number</FormLabel>
              <FormControl>
                <PhoneInput placeholder="Input a phone number." {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <footer className="mt-2">
          <Button type="submit">Save changes</Button>
        </footer>
      </form>
    </Form>
  )
}

const changePasswordSchema = z
  .object({
    currentPassword: z.string({ message: "Current password is required" }),
    newPassword: z
      .string({ message: "New password is required" })
      .min(8, "Password must be at least 8 characters long")
      .max(64, "Password must be at most 64 characters long")
      .regex(/[a-z]/, "Password must contain at least one lowercase letter")
      .regex(/[A-Z]/, "Password must contain at least one uppercase letter")
      .regex(/\d/, "Password must contain at least one digit")
      .regex(
        /[^a-zA-Z\d]/,
        "Password must contain at least one special character",
      ),
    repeatPassword: z.string({ message: "Repeat password is required" }),
  })
  .superRefine((data, ctx) => {
    if (data.newPassword !== data.repeatPassword) {
      ctx.addIssue({
        code: "custom",
        message: "Repeat password must match new password",
        path: ["repeatPassword"],
      })
    }

    if (data.currentPassword === data.newPassword) {
      ctx.addIssue({
        code: "custom",
        message: "New password must be different from current password",
        path: ["newPassword"],
      })
    }
  })

function ChangePassword() {
  const { data: userInfo } = useGetUserQuery()
  const [updatePassword] = useUpdateUserPasswordMutation()

  const form = useForm<z.infer<typeof changePasswordSchema>>({
    resolver: zodResolver(changePasswordSchema),
    defaultValues: {
      currentPassword: "",
      newPassword: "",
      repeatPassword: "",
    },
  })

  async function handlePasswordChange(
    data: z.infer<typeof changePasswordSchema>,
  ) {
    const loadingToastId = toast.loading("Changing your password...")
    const result = await updatePassword({
      current_password: data.currentPassword,
      new_password: data.newPassword,
    })

    if (result.error) {
      if (
        "data" in result.error &&
        "detail" in (result.error.data as Record<string, unknown>)
      ) {
        const detail = (result.error.data as { detail: string }).detail

        toast.error("Failed to change password!", {
          description: detail,
          id: loadingToastId,
        })

        if (detail.toLowerCase().includes("current password")) {
          form.setError("currentPassword", {
            type: "manual",
            message: detail,
          })
        }
      } else {
        toast.error("Failed to change password!", {
          id: loadingToastId,
        })
      }
    } else {
      toast.success("Password changed!", {
        description: "Your password has been changed successfully.",
        id: loadingToastId,
      })

      form.reset()
    }
  }

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(handlePasswordChange)}
        className="flex flex-col gap-2"
      >
        <h3 className="font-500">Change Password</h3>

        {/* For accessibility */}
        <input
          type="text"
          name="email"
          value={userInfo?.email}
          autoComplete="username email"
          className="sr-only"
          readOnly
        />

        <FormField
          name="currentPassword"
          control={form.control}
          render={({ field }) => (
            <FormItem>
              <FormLabel>Current Password</FormLabel>
              <FormControl>
                <ToggleableInput
                  placeholder="old password"
                  type="password"
                  autoComplete="current-password"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          name="newPassword"
          control={form.control}
          render={({ field }) => (
            <FormItem>
              <FormLabel>New Password</FormLabel>
              <FormControl>
                <ToggleableInput
                  placeholder="new password"
                  type="password"
                  autoComplete="new-password"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          name="repeatPassword"
          control={form.control}
          render={({ field }) => (
            <FormItem>
              <FormLabel>Repeat Password</FormLabel>
              <FormControl>
                <Input
                  placeholder="••••••••"
                  type="password"
                  autoComplete="off"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <footer className="mt-2">
          <Button type="submit">Change password</Button>
        </footer>
      </form>
    </Form>
  )
}

function UpdatableProfilePicture() {
  const { data: userInfo } = useGetUserQuery()

  return (
    <div className="flex justify-center">
      <div className="flex justify-center items-center relative justify-self-center w-max">
        <Avatar
          size={128}
          name={userInfo?.first_name}
          photo={userInfo?.photo}
        />

        <UpdateAvatarModal
          trigger={
            <button className="absolute rounded-50 bg-blue-300 hover:bg-blue-50 w-10 h-10 flex justify-center items-center bottom-0 right-0">
              <Icon name="Camera" />
            </button>
          }
        />
      </div>
    </div>
  )
}

interface ProfileModalNewProps {
  isOpen: boolean
  setOpen: (open: boolean) => void
}

export default function ProfileModalNew({
  isOpen,
  setOpen,
}: ProfileModalNewProps) {
  return (
    <Dialog open={isOpen} onOpenChange={setOpen}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Account Settings</DialogTitle>
        </DialogHeader>
        <Tabs defaultValue="account">
          <TabsList className="grid w-full grid-cols-2 mt-1">
            <TabsTrigger value="account">Profile</TabsTrigger>
            <TabsTrigger value="password">Password</TabsTrigger>
          </TabsList>
          <TabsContent value="account" className="w-full space-y-5 pt-4">
            <UpdatableProfilePicture />
            <ChangeProfileData />
          </TabsContent>
          <TabsContent value="password" className="w-full space-y-5 pt-4">
            <ChangePassword />
          </TabsContent>
        </Tabs>
      </DialogContent>
    </Dialog>
  )
}
