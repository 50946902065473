import { useState } from "react"

import { Icon } from "@/assets"
import { Button } from "@/components/ui/button"
import { DeleteProjectDialog } from "@/components/allProjects/DeleteProjectDialog"
import { CreateOrRenameProjectDialog } from "@/components/allProjects/CreateOrRenameProjectDialog"
import { ProjectSchema } from "@/api/resources/types"

interface TopProps {
  project: ProjectSchema
  toggleDropdown: () => void
  toggleShare: () => void
  onExportProject: () => void
}

export default function ProjectHeader({
  project,
  toggleDropdown,
  toggleShare,
  onExportProject,
}: TopProps) {
  const [isRenameModal, setRenameModal] = useState<boolean>(false)
  const [isDeleteModal, setDeleteModal] = useState<boolean>(false)

  return (
    <div className="bg-bgSecondary w-full flex justify-center">
      <div className="flex justify-between py-8 wrapper">
        <div className="flex items-center gap-2 text-24 text-black font-500">
          <Icon name="Case" />
          {project.display_name}
          <p className="bg-blue-200 text-white px-2 py-0.5 text-xs rounded-full capitalize font-semibold">
            {project.role}
          </p>
        </div>

        <div className="flex items-center text-14 leading-6">
          <Button
            variant="ghost"
            className="text-blue-200 gap-1.5"
            onClick={toggleDropdown}
          >
            <Icon name="Export" />
            Upload Document
          </Button>

          <div className="h-4 border-l border-[#B6CADE]" role="none"></div>

          <Button
            variant="ghost"
            className="gap-1.5"
            style={{ color: "#4975A1" }}
            onClick={onExportProject}
          >
            <Icon name="Case" className="h-[24px]" />
            Export Project
          </Button>

          <div className="h-4 border-l border-[#B6CADE]" role="none"></div>

          <Button
            variant="ghost"
            className="gap-1.5"
            onClick={toggleShare}
            style={{ color: "#4975A1" }}
            // FIXME: replace this color everywhere !!!
          >
            <Icon name="Share" className="h-[20px]" />
            Share
          </Button>

          <div className="h-4 border-l border-[#B6CADE]" role="none"></div>

          <Button
            variant="ghost"
            className="gap-1.5"
            style={{ color: "#4975A1" }}
            onClick={() => {
              setRenameModal(true)
            }}
          >
            <Icon name="Edit" className="h-[20px]" />
            Rename
          </Button>

          <div className="h-4 border-l border-[#B6CADE]" role="none"></div>

          <Button
            variant="ghost"
            className="gap-1.5 text-background-lightRed"
            disabled={isDeleteModal}
            onClick={() => {
              setDeleteModal(true)
            }}
          >
            <Icon name="Delete" className="h-[20px]" />
            Delete
          </Button>
        </div>
      </div>
      <CreateOrRenameProjectDialog
        projectId={project.project_id}
        initialName={project.display_name}
        action="rename"
        open={isRenameModal}
        onOpenChange={setRenameModal}
      />
      <DeleteProjectDialog
        projectId={project.project_id}
        open={isDeleteModal}
        onOpenChange={setDeleteModal}
      />
    </div>
  )
}
