import { Guy, Icon, Jacob, Wade } from "@/assets"
import { Button, Dropdown } from "@/elements"
import { useMemo, useRef, useState } from "react"
import {
  useAddProjectCollaboratorMutation,
  useGetProjectCollaboratorsQuery,
  useRemoveProjectCollaboratorMutation,
  useUpdateProjectCollaboratorMutation,
} from "@/api/resources"
import AccessDropdown from "@/components/Project/project/ProjectHeader/shareModal/AccessDropdown"
import { AccessLevels } from "@/components/Project/project/ProjectHeader/shareModal/types"
import Avatar from "@/components/header/profileSettings/Avatar"

interface ShareModalProps {
  projectId: string
  toggleShare: () => void
}

export default function ShareModal({
  projectId,
  toggleShare,
}: ShareModalProps) {
  const inputRef = useRef<HTMLInputElement>(null)
  const [linkCopied, setLinkCopied] = useState<boolean>(false)

  const [inputEmail, setInputEmail] = useState<string>("")
  const [inputRole, setInputRole] = useState<AccessLevels>(AccessLevels.Editor)

  const [globalRole, setGlobalRole] = useState<string | null>(null)

  const { data: people } = useGetProjectCollaboratorsQuery(projectId)
  const [addProjectCollaborator, addResult] =
    useAddProjectCollaboratorMutation()
  const [updateProjectCollaborator, updateResult] =
    useUpdateProjectCollaboratorMutation()

  const [removeProjectCollaborator, removeResult] =
    useRemoveProjectCollaboratorMutation()

  const copyToClipboard = () => {
    if (inputRef.current) {
      navigator.clipboard.writeText(inputRef.current.value)
      setLinkCopied(true)
      setTimeout(() => {
        setLinkCopied(false)
      }, 3000)
    }
  }

  // TODO: default avatar
  const DEFAULT_AVATAR = process.env.NODE_ENV === "development" ? Guy : ""

  return (
    <div className="p-2 flex flex-col gap-8">
      <div className="flex justify-between items-center">
        <h3 className="font-400 text-22 leading-7">Share this project</h3>
        <button
          onClick={toggleShare}
          className="flex gap-1.5 text-text-neutral text-16 font-500"
        >
          <Icon name={"Close24"} />
        </button>
      </div>

      <div className="flex gap-2">
        <div
          className="flex justify-between items-center gap-2 rounded-8 py-4 px-4 w-3/4"
          style={{ border: "1px solid #B6CADE" }}
        >
          <input
            type="text"
            placeholder="Enter Email"
            value={inputEmail}
            onChange={(e) => {
              setInputEmail(e.target.value)
            }}
            className="text-15 leading-4 font-500"
          />

          <AccessDropdown
            defaultValue={inputRole}
            onValueChange={(value) => {
              setInputRole(value)
            }}
          />
        </div>

        <Button
          className="lg-pri-btn rounded-7 w-1/4"
          onClick={() => {
            addProjectCollaborator({
              project_id: projectId,
              email: inputEmail,
              role: inputRole,
            })
            setInputEmail("")
          }}
        >
          Invite
        </Button>
      </div>

      <div className="flex flex-col gap-6">
        <h3 className="text-black text-15 leading-5">People with access</h3>

        {people.map((person, _) => (
          <div
            key={_}
            className="grid grid-cols-[auto_1fr_auto] gap-4 items-center"
          >
            <Avatar name={person?.first_name} photo={person?.photo} />

            <div className=" flex flex-col overflow-hidden">
              <p className="text-15 leading-4 text-black truncate">
                {person?.first_name} {person?.last_name}
              </p>
              <p className="text-13 leading-4 text-gray-400">{person?.email}</p>
            </div>

            {person.role === AccessLevels.Owner ? (
              <p className="text-14 leading-4 text-gray-400">
                {AccessLevels.Owner}
              </p>
            ) : (
              <div className="flex gap-5">
                <AccessDropdown
                  defaultValue={person.role}
                  onValueChange={(value) => {
                    updateProjectCollaborator({
                      project_id: projectId,
                      email: person.email,
                      role: value,
                    })
                  }}
                />

                <Button
                  onClick={() => {
                    removeProjectCollaborator({
                      project_id: projectId,
                      email: person.email,
                    })
                  }}
                >
                  <Icon name="Close" />
                </Button>
              </div>
            )}
          </div>
        ))}
      </div>

      <div className="flex flex-col gap-4 hidden">
        <h3 className="text-black text-15 leading-5">General access</h3>

        <div className="grid grid-cols-[auto_1fr_auto] gap-2 items-center">
          <Icon name="Link" />

          <div className=" flex flex-col overflow-hidden">
            <p className="text-15 leading-4 text-black truncate">
              Anyone with the link
            </p>
            <p className="text-13 leading-4 text-gray-400">
              Anyone on the internet with the link can view
            </p>
          </div>

          <Dropdown
            options={["Can view", "Can edit", "None"]}
            value={globalRole}
            onChange={(val) => setGlobalRole(val)}
            icon="BlackArrow"
            parentClassName="text-13 leading-4 font-500"
            currentBtnClassName="flex items-center text-13 leading-4 font-500"
            optDivClassName="bg-white rounded gap-3 p-3 border border-border-gray top-0 w-max"
          />
        </div>

        <div className="flex justify-between items-center gap-2 py-5 px-4 rounded-6 bg-bgSecondary">
          <input
            ref={inputRef}
            type="text"
            disabled
            value="https://app.momentum/files/pR56G..."
            className="text-14 leading-3 font-400 text-gray-400"
          />

          {linkCopied ? (
            <div className="flex gap-2 items-center text-black text-14 leading-3 font-500">
              Copied!
            </div>
          ) : (
            <button
              onClick={copyToClipboard}
              className="flex gap-2 items-center text-blue-200 text-14 leading-3 font-500"
            >
              Copy Link
              <Icon name="Copy" />
            </button>
          )}
        </div>
      </div>
    </div>
  )
}
