import { DocumentSchema } from "@/api/resources"
import { ProjectStatus } from "@/elements"
import DocumentDropdown from "./document/DocumentDropdown"
import { CaretRightIcon } from "@radix-ui/react-icons"
import { Link } from "react-router-dom"

export default function SemanticMatchedDocuments({
  provision,
  documents,
}: {
  provision: string
  documents: DocumentSchema[]
}) {
  return (
    <div className="flex flex-col gap-6">
      <p className="text-16 text-gray-400">
        <span className="text-black">{documents.length}</span> matches found
      </p>

      {documents.map((document) => (
        <article className="border border-border-gray rounded-md p-6 flex flex-col gap-2 hover:cursor-pointer">
          <header className="flex justify-between items-center">
            <Link
              className="flex flex-col gap-2 flex-1"
              to={`/document/${document.document_id}`}
            >
              <hgroup>
                <h4>{document.display_name}</h4>
                <p className="text-gray-400">{document.title ?? "-"}</p>
              </hgroup>

              <div className="flex gap-2 items-center text-14 text-gray uppercase">
                <p className="opacity-40">{document.file_type}</p>
                {document.language ? (
                  <p className="opacity-80 bg-blue-200 text-white px-1 py-0.5 rounded-md">
                    {document.language}
                  </p>
                ) : null}
                <ProjectStatus status={document.status} />
              </div>
            </Link>

            <DocumentDropdown document={document} />
          </header>

          <div role="none" className="h-[1px] w-full bg-gray-300" />

          <ul>
            {document.semantic_matches?.map((match) => (
              <li
                key={match.idx}
                className="whitespace-nowrap focus-within:bg-blue-200/25 hover:bg-blue-200/25 hover:pl-4 focus-within:pl-4 transition-all text-gray hover:text-black focus-within:text-black rounded-md py-1"
              >
                <Link
                  to={`/document/${document.document_id}?provision=${provision.replaceAll(" ", "_").toLowerCase()}#${match.idx}`}
                  className="focus:outline-none block w-full overflow-ellipsis overflow-hidden"
                >
                  <CaretRightIcon className="inline mr-2 ml-0" />
                  {match.text}
                </Link>
              </li>
            ))}
          </ul>
        </article>
      ))}
    </div>
  )
}
