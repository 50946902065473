import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { z } from "zod"
import { zodResolver } from "@hookform/resolvers/zod"
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
} from "@/components/ui/form"
import { Input } from "@/components/ui/input"
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTrigger,
} from "@/components/ui/dialog"
import { Button } from "@/components/ui/button"
import { Icon } from "@/assets"
import { ReloadIcon } from "@radix-ui/react-icons"
import {
  useCreateProjectMutation,
  useRenameProjectMutation,
} from "@/api/resources"

const createProjectSchema = z.object({
  project_name: z.string().min(2).max(255),
})

export interface Params {
  initialName?: string | null
  action: "create" | "rename"
  projectId?: string
  open: boolean
  onOpenChange: (boolean) => void
  showButton?: boolean
}

export const CreateOrRenameProjectDialog = ({
  initialName,
  action,
  projectId,
  open,
  onOpenChange,
  showButton,
}: Params) => {
  const [
    createProject,
    {
      isLoading: isCreating,
      isSuccess: isCreateSuccess,
      isError: isCreateError,
    },
  ] = useCreateProjectMutation()
  const [
    renameProject,
    {
      isLoading: isRenaming,
      isSuccess: isRenameSuccess,
      isError: isRenameError,
    },
  ] = useRenameProjectMutation()

  const isLoading = isCreating | isRenaming
  const isSuccess = action === "create" ? isCreateSuccess : isRenameSuccess
  const isError = action === "create" ? isCreateError : isRenameError

  const form = useForm<z.infer<typeof createProjectSchema>>({
    resolver: zodResolver(createProjectSchema),
    values: {
      project_name: initialName ?? "",
    },
  })
  const renderForm = ({ field }) => {
    return (
      <FormItem>
        <FormLabel>Project name</FormLabel>
        <FormControl>
          <Input
            type="text"
            placeholder="Enter name of the project"
            {...field}
          />
        </FormControl>
        <FormDescription>
          The project name should have between 2 and 255 characters.
        </FormDescription>
      </FormItem>
    )
  }

  function onSubmit(values: z.infer<typeof createProjectSchema>) {
    if (action === "create")
      createProject({ display_name: values.project_name })
    else
      renameProject({
        project_id: projectId,
        display_name: values.project_name,
      })
  }

  useEffect(() => {
    if (isSuccess) {
      onOpenChange(false)
      form.reset()
    } else if (isError) {
      // TODO: show error toast
    }
  }, [isSuccess, isError])

  return (
    <>
      <Dialog open={open} onOpenChange={onOpenChange}>
        {showButton && (
          <DialogTrigger asChild>
            <Button
              variant="ghost"
              className="flex items-center text-blue-200 font-500 text-16 gap-1.5"
            >
              <Icon name="Plus" />{" "}
              <span className="text-blue-200 text-15">Create Project</span>
            </Button>
          </DialogTrigger>
        )}
        <DialogContent>
          <DialogHeader>
            <h3 className="text-22 text-black text-center">
              {action === "create" ? "Create Project" : "Rename Project"}
            </h3>
          </DialogHeader>
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
              <FormField
                control={form.control}
                name="project_name"
                render={renderForm}
              />
              <Button
                variant="secondary"
                onClick={() => {
                  form.reset()
                  onOpenChange(false)
                }}
              >
                Cancel
              </Button>
              <Button className="ml-2" type="submit">
                {isLoading ? (
                  <ReloadIcon className="mr-2 h-4 w-4 animate-spin" />
                ) : (
                  ""
                )}
                {action === "create" ? "Create" : "Rename"}
              </Button>
            </form>
          </Form>
        </DialogContent>
      </Dialog>
    </>
  )
}
