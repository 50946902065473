import { useEffect, useState } from "react"

import { Modal } from "@/elements"
import ProjectHeader from "./ProjectHeader"
import ShareModal from "./ProjectHeader/shareModal"
import { useLazyExportProjectDocumentsQuery } from "@/api/resources"
import ProjectsSearch from "@/components/Project/project/main/ProjectsSearch"
import { ProjectSchema } from "@/api/resources/types"
import ProjectContext, {
  useOnUploadFileChange,
  useProvisionProjectContext,
} from "@/components/Project/ProjectContext"
import { UploadFile } from "@/components/UploadFile"
import { Icon } from "@/assets"
import { toast } from "sonner"

interface ProjectProps {
  project: ProjectSchema
}

export default function Project({ project }: ProjectProps) {
  const { provision } = useProvisionProjectContext()
  const [exportDocuments] = useLazyExportProjectDocumentsQuery()

  const [openDocUploading, setOpenDocUploading] = useState<boolean>(false)
  const [openShare, setOpenShare] = useState<boolean>(false)
  const { handleFileChange, chosenFiles } = useOnUploadFileChange()

  const toggleDropdown = () => setOpenDocUploading((prev) => !prev)
  const toggleShare = () => setOpenShare((prev) => !prev)

  useEffect(() => {
    // if chosen files have changed, close the upload dropdown
    setOpenDocUploading(false)
  }, [chosenFiles])

  // TODO: we should have a loading state to avoid showing EmptyProject
  return (
    <ProjectContext.Provider value={project}>
      <ProjectHeader
        project={project}
        toggleDropdown={toggleDropdown}
        toggleShare={toggleShare}
        onExportProject={async () => {
          const loadingToastId = toast.loading("Exporting project...")
          const result = await exportDocuments({
            project_id: project.project_id,
            provision,
          })

          if (result.isError) {
            toast.error("Failed to export project.", {
              description: "Please try again later.",
              id: loadingToastId,
            })
          } else {
            toast.success("Project exported successfully.", {
              id: loadingToastId,
            })
          }
        }}
      />

      {project && (
        <div className="py-6 wrapper">
          <ProjectsSearch project={project} />
        </div>
      )}

      <Modal
        cls="max-w-xl"
        title="Upload Documents"
        isOpen={openDocUploading}
        setOpen={setOpenDocUploading}
      >
        {/* TODO: take the title and header style from UploadModal! */}
        <div className="flex justify-between items-center">
          <h3 className="font-400 text-22 leading-7">Upload Documents</h3>
          <button
            onClick={toggleDropdown}
            className="flex gap-1.5 text-text-neutral text-16 font-500"
          >
            <Icon name={"Close24"} />
          </button>
        </div>
        <UploadFile onUpload={handleFileChange} dashed multiple />
      </Modal>

      <Modal isOpen={openShare} setOpen={setOpenShare} cls="max-w-xl">
        <ShareModal projectId={project.project_id} toggleShare={toggleShare} />
      </Modal>
    </ProjectContext.Provider>
  )
}
